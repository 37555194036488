<template>
  <h1 class="display-4 font-weight-light">
    <slot />
  </h1>
</template>

<script>
  export default {
    name: 'PagesHeading'
  }
</script>
